/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getRouteCarForTotalTime } from "../../../actions/navitimeAPI";
import moment from "moment";
import AdminInputLocation from "../components/AdminInputLocation";
import AdminInputDatePicker from "../components/AdminInputDatePicker";
import Select,{components} from "react-select";
import { travelAreaOptionsEnglish } from "../../../utils/dataLists";
import AdminInputTimePicker from "../components/AdminInputTimePicker";
import AdminInputPassengers from "../components/AdminInputPassengers";
import AdminInputLuggage from "../components/AdminInputLuggage";
import LuggageIcon from "../../../icons/form-icons/luggage.svg";
import date_icon from "../../../icons/dashboard/date_icon.svg";
import LocationPinIcon from "../../../icons/form-icons/location-pin.svg";
import PaxIcon from "../../../icons/form-icons/pax.svg";
import ClockIcon from "../../../icons/form-icons/clock.svg";
import TravelAreaIcon from "../../../icons/form-icons/travel-area.svg";

export const ByTheHourQuotation = ({searchAvailableVehicles, editFormAvailableVehicle = null}) => {
  // ! START OF  BY THE HOUR DATA

  const [halfHourIntervalsData, setHalfHourIntervalsData] = useState([]);

  const [byTheHourForm, setByTheHourForm] = useState({
    pickupLocation: null,
    dropoffLocations: [],
    usageDuration: null,
    travelDate: null,
    pickupTimeRaw: null,
    estpPickupTime: null,
    travelArea : null,
    luggage: {
      cabinSize: 0,
      checkinSize: 0,
    },
    pax: {
      adult: 0,
      child: 0,
      toddler: 0,
      baby: 0,
    },
    byTheHourBook: true,
    bookingType : "byTheHour",
    isFromAirport: false,
    isToAirport: false,
  });

  useEffect(() => {
    if(editFormAvailableVehicle != null) {
      console.log("ADA EDIT NYA")
      setByTheHourForm(editFormAvailableVehicle);
    } 
  }, [])
  

  const handleByTheHourPickupLocation = (location) => {
    if (location === undefined) return;
    const city = location.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    location.lat = location.geometry.location.lat;
    location.lon = location.geometry.location.lng;
    location.label = location.name + ", " + location.formatted_address;
    location.formatted_address = location.name + ", " + location.formatted_address;
    location.value = location.place_id;
    location.city = city;
    location.prefecture = city;
    setByTheHourForm({ ...byTheHourForm, pickupLocation: location });
  };

  const handleByTheHourDropOffLocation = (location) => {
    const city = location.address_components.find((c) =>
    c.types[0].includes("administrative_area_level_1")
    ).long_name;
    location.lat = location.geometry.location.lat;
    location.lon = location.geometry.location.lng;
    location.label = location.name + ", " + location.formatted_address;
    location.formatted_address = location.name + ", " + location.formatted_address;
    location.value = location.place_id;
    location.city = city;
    location.prefecture = city;
    setByTheHourForm({ ...byTheHourForm, dropoffLocations: [location] });
  };

  const handleBytheHourTravelDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setByTheHourForm({...byTheHourForm, travelDate: formattedDate });
  };

  const handleBytheHourPickupTime = (time) => {
    const formattedTime = moment(time).format("HH:mm");
    setByTheHourForm({ ...byTheHourForm, pickupTimeRaw: formattedTime });
  };

  const handleOnChangeUsageDuration = (selectedOption) => {
    setByTheHourForm({ ...byTheHourForm, usageDuration: selectedOption.value });
  };

  const handleOnChangeTravelArea = (selectedOption) => {
    setByTheHourForm({ ...byTheHourForm, travelArea: selectedOption.value });
  };

  const handleOnChangeLuggage = (luggage, amount) => {
    setByTheHourForm((prevForm) => {
      const newLugage = { ...prevForm.luggage };

      if (luggage === "cabin") {
        newLugage.cabinSize = Math.max(0, newLugage.cabinSize + amount);
      } else if (luggage === "checkin") {
        newLugage.checkinSize = Math.max(0, newLugage.checkinSize + amount);
      }

      return {
        ...prevForm,
        luggage: newLugage,
      };
    });
  };

  const handleOnChangePax = (pax, amount) => {
    setByTheHourForm((prevForm) => {
      const newPax = { ...prevForm.pax };

      if (pax === "adult") {
        newPax.adult = Math.max(0, newPax.adult + amount);
      } else if (pax === "child") {
        newPax.child = Math.max(0, newPax.child + amount);
      } else if (pax === "toddler") {
        newPax.toddler = Math.max(0, newPax.toddler + amount);
      } else if (pax === "baby") {
        newPax.baby = Math.max(0, newPax.baby + amount);
      }

      return {
        ...prevForm,
        pax: newPax,
      };
    });
  };

  const checkTotalTime = async (sfd) => {
    const start =
      sfd.pickupLocation.lat +
      "%2C" +
      sfd.pickupLocation.lon;
    const end =
      sfd.dropoffLocations[0].lat +
      "%2C" +
      sfd.dropoffLocations[0].lon;

    const car_r = await getRouteCarForTotalTime(start, end);


    
    const { total_time } = getSelectedRouteData(car_r);

    populateDurationSelect(total_time);
  };

  /**
   * * Get the selected route's data
   * @param total_time - return parameter - total time in minutes
   */
  const getSelectedRouteData = (car_r) => {
    const summary_arr = car_r[car_r.length - 1].summary;

    const total_time = summary_arr.move.time;

    return {
      total_time,
    };
  };

  function populateDurationSelect(minimal_duration) {
    setHalfHourIntervalsData([]);
    const start_from = Math.round(minimal_duration / 30);
    const halfHourIntervals = Array.from({ length: 49 }, (_, i) => {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30;
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      const label = `${hours.toString().padStart(2, "0")} Hours ${minutes
        .toString()
        .padStart(2, "0")} Minutes`;

      return {
        value: time,
        label: label,
      };
    });
    const final_hour_data = halfHourIntervals.slice(start_from + 2);
    setHalfHourIntervalsData(final_hour_data);
  }

  useEffect(() => {
    if (
      byTheHourForm.pickupLocation != null &&
      byTheHourForm.dropoffLocations != null &&
      byTheHourForm.dropoffLocations.length > 0
    ) {
      checkTotalTime(byTheHourForm);
      const { pickupStatus, dropoffStatus } = checkIsAirport(
        byTheHourForm.pickupLocation,
        byTheHourForm.dropoffLocations
      );

      console.log(pickupStatus, dropoffStatus);

      setByTheHourForm({ ...byTheHourForm, isFromAirport: pickupStatus, isToAirport: dropoffStatus });
      
    }
  }, [byTheHourForm.pickupLocation, byTheHourForm.dropoffLocations]);

  useEffect(() => {
    const est = estimateTimeToDestinationWithDuration(byTheHourForm);
    setByTheHourForm({ ...byTheHourForm, estpPickupTime: est ?? "" });
  }, [byTheHourForm.estpPickupTime, byTheHourForm.pickupTimeRaw]);

  // ! END OF  BY THE HOUR DATA

  const checkIsAirport = (pickupLocation, dropoffLocations) => {
    var pickUpStatus = false;
    var dropoffStatus = false;

    if (pickupLocation) {
      if (pickupLocation.types.includes("airport")) pickUpStatus = true;

      const trimmedPlace = pickupLocation.formatted_address
        .toLowerCase()
        .split(",");

      for (let index = 0; index < trimmedPlace.length; index++) {
        if (
          (trimmedPlace[index].includes("haneda") &&
            trimmedPlace[index].includes("airport")) ||
          (trimmedPlace[index].includes("羽田") &&
            trimmedPlace[index].includes("空港"))
        ) {
          pickUpStatus = true;
        }

        if (
          (trimmedPlace[index].includes("narita") &&
            trimmedPlace[index].includes("airport")) ||
          (trimmedPlace[index].includes("成田") &&
            trimmedPlace[index].includes("空港"))
        ) {
          pickUpStatus = true;
        }
      }
    }

    if (dropoffLocations.length > 0) {
      for (let i = 0; i < dropoffLocations.length; i++) {
        if (dropoffLocations[i].types.includes("airport")) dropoffStatus = true;

        const trimmedPlaceDropoff = dropoffLocations[i].formatted_address
          .toLowerCase()
          .split(",");

        for (let ind = 0; ind < trimmedPlaceDropoff.length; ind++) {
          if (
            (trimmedPlaceDropoff[ind].includes("haneda") &&
              trimmedPlaceDropoff[ind].includes("airport")) ||
            (trimmedPlaceDropoff[ind].includes("羽田") &&
              trimmedPlaceDropoff[ind].includes("空港"))
          ) {
            dropoffStatus = true;
          }

          if (
            (trimmedPlaceDropoff[ind].includes("narita") &&
              trimmedPlaceDropoff[ind].includes("airport")) ||
            (trimmedPlaceDropoff[ind].includes("成田") &&
              trimmedPlaceDropoff[ind].includes("空港"))
          ) {
            dropoffStatus = true;
          }
        }
      }
    }

    return {
      pickupStatus: pickUpStatus,
      dropoffStatus: dropoffStatus,
    };
  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
   * ? sfd - Selected Form Data
   * ? timeString - pickup time in string 'HH:MM'
   * @param sfd
   * @param timeString - not required
   * @param duration - selected duration
   */
  const estimateTimeToDestinationWithDuration = (sfd, timeString) => {
    const pickupTime =  moment.utc(sfd.pickupTimeRaw, 'HH:mm').local().format('HH:mm');
    const duration = sfd.usageDuration;

    if (
      pickupTime === "" ||
      pickupTime == null ||
      duration === "" ||
      duration == null
    ) {
      return "N/A";
    }

    const timeToAdd = timeString === undefined ? pickupTime : timeString;

    const [hours, minutes] = timeToAdd.split(":").map(Number);
    const [hoursDuration, minutesDuration] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;

    const totalDurations = hoursDuration * 60 + minutesDuration;

    const newTotalMinutes = (totalMinutes + totalDurations) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
      2,
      "0"
    )}`;
  };


  useEffect(() => {
    if(byTheHourForm.pickupLocation != null && byTheHourForm.dropoffLocations!= null && byTheHourForm.dropoffLocations.length > 0 && byTheHourForm.usageDuration != null && byTheHourForm.travelDate != null && byTheHourForm.pickupTimeRaw != null && byTheHourForm.estpPickupTime != null && Object.values(byTheHourForm.pax).some(paxCount => paxCount > 0)){
        console.log("SEARCH AVALABLE VEHICLE");
        searchAvailableVehicles(byTheHourForm);
    }
  }, [byTheHourForm])


const UsageDurationSelectComponent = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
         
        <img src={ClockIcon} alt="" srcSet="" 
        style={{ position: 'absolute', left: 8, paddingLeft : 8 }}  />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};


const TravelAreaSelectComponent = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
        <img src={TravelAreaIcon} alt="" srcSet="" 
        style={{ position: 'absolute', left: 8, paddingLeft : 8 }}  />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};


const styles = {
  valueContainer: base => ({
    ...base,
    paddingLeft: 43
  })
}
  
  return (
    <div className="">
      <div className="row mb-2">
        <div className="col-6">
          <AdminInputLocation
            prefixIcon={LocationPinIcon}
            placeholder="Pick-up: Airport, Hotel, Attraction, etc"
            onSelectLocation={handleByTheHourPickupLocation}
            mb="mb-2"
            label={"Pick-up"}
          />
        </div>
        <div className="col-6">
          <AdminInputLocation
            prefixIcon={LocationPinIcon}
            placeholder="Drop-off: Airport, Hotel, Attraction, etc."
            onSelectLocation={handleByTheHourDropOffLocation}
            mb="mb-2"
            label={"Drop-off"}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <AdminInputDatePicker
            label={"Travel Date"}
            placeholder="Select travel date"
            prefixIcon={date_icon}
            onSelectDate={handleBytheHourTravelDate}
            mb={""}
            name="travelDate"
            value={ byTheHourForm.travelDate != null  ? moment(byTheHourForm.travelDate, "YYYY-MM-DD").toDate() : null}
          />
        </div>
        <div className="col-3">
          <label htmlFor="" className="mb-1">
            Usage Duration
          </label>
          <Select
            components={{ValueContainer: UsageDurationSelectComponent}}
            options={halfHourIntervalsData}
            onChange={handleOnChangeUsageDuration}
            styles={styles}
          ></Select>
        </div>
        <div className="col-3">
          <label htmlFor="" className="mb-1">
            Travel Area
          </label>
          <Select options={travelAreaOptionsEnglish} onChange={handleOnChangeTravelArea} components={{ValueContainer : TravelAreaSelectComponent}} styles={styles}></Select>
        </div>
        <div className="col-3">
          <AdminInputTimePicker
            label={"Pick-up Time"}
            placeholder="Select Pick-up Time"
            prefixIcon={date_icon}
            onSelectDate={handleBytheHourPickupTime}
            name="travelDate"
            mb="mb-0"
            value={byTheHourForm.pickupTimeRaw != null  ? moment.utc(byTheHourForm.pickupTimeRaw, "HH:mm").toDate() : null}
          />
          <p className="fs-13 mt-1 mb-0">
            Est. Drop-off Time:{" "}
            <span className="fw-bold">
              {byTheHourForm.estpPickupTime ?? ""}
            </span>
          </p>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <AdminInputPassengers
            label={"Passenger"}
            placeholder="Input Passenger"
            prefixIcon={PaxIcon}
            totalAdult={byTheHourForm.pax.adult}
            totalBaby={byTheHourForm.pax.baby}
            totalToddler={byTheHourForm.pax.toddler}
            totalChild={byTheHourForm.pax.child}
            onChange={handleOnChangePax}
            value={
              byTheHourForm.pax.adult +
              byTheHourForm.pax.child +
              byTheHourForm.pax.toddler +
              byTheHourForm.pax.baby
            }
          />
        </div>
        <div className="col-3">
          <AdminInputLuggage
            label={"Luggage"}
            placeholder="Input Luggage"
            prefixIcon={LuggageIcon}
            totalCabin={byTheHourForm.luggage.cabinSize}
            totalCheckin={byTheHourForm.luggage.checkinSize}
            onChange={handleOnChangeLuggage}
            value={
              byTheHourForm.luggage.cabinSize + byTheHourForm.luggage.checkinSize
            }
          />
        </div>
      </div>
    </div>
  );
};
