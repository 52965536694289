
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import {
  getBooking,
  updateBookingStatus,
  getAvailableVehicles,
  updateDriver,
  addNote,
  getCart,
  getBookingsByCartID,
} from "../../../actions/admin/booking";

import ClockIcon from "../../../icons/form-icons/clock.svg";
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from "react-datepicker";
import {
  chargeToken,
  chargeTokenEditedFee,
  chargeTokenAdditionalFee,
  chargeTokenByCartId,
  chargeTokenWithRequestedChanges,
} from "../../../actions/admin/flywire";
import Alert from "../../layout/Alert";
import { setAlert } from "../../../actions/alert";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "../../../utils/customMoment";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { bookingStatusOptions } from "../../../utils/dataLists";
import { getAllDrivers } from "../../../actions/admin/driver";
import {
  getTemplate,
  getTemplates,
  sendMail,
  sendMailChangeCCInfo
} from "../../../actions/admin/mail";
import { getSetting } from "../../../actions/admin/settings";
import { getBookingCancelEmailTemplate } from "../../../utils/EmailTemplate";
import { useLoading } from "../../../components/front-pages-new-theme/LoadingContext";
import ModalConfirmation from "../../front-pages-new-theme/ModalConfirmation";

const ManageBookingV2 = ({ match }) => {
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const history = useHistory();
  const [activeVehicles, setActiveVehicles] = useState([]);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [ddltemplate, setDDlTemplates] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [driverSelectedOption, setDriverSelectedOption] = useState([]);
  const [ddltemplateOption, setddlTemplateOption] = useState(null);
  const { showLoading, closeLoading } = useLoading();

  const dispatch = useDispatch();
  const template_state = useSelector((state) => state.template);
  const { templates } = template_state;
  const bookingState = useSelector((state) => state.booking);
  const { loading, booking } = bookingState;
  const [style, setStyle] = useState("");
  const [note, setNote] = useState("");
  const [bookingStatus, setBookingStatus] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [subject, setSubject] = useState("");
  const [updatedHtmlstate, setUpdatedHtmlstate] = useState(null);

  const [labelData, setLabelData] = useState({});

  const [editHourUsage, setEditHourUsage] = useState(false);
  const [newHourUsage, setNewHourUsage] = useState(null);

  const [checkChargeLoading, setcheckChargeLoading] = useState(true);
  const [bookingsWithCartIdLength, setBookingsWithCartIdLength] = useState(1);
  const [chargeAccount, setChargeAccount] = useState("");

  const [addChildSeat, setAddChildSeat] = useState(false);
  const [newChildSeat, setNewChildSeat] = useState({
    infant: 0,
    toddler: 0,
    child: 0,
  });

  //handle add setNewChildSeat increment
  const handleAddChildSeat = (type) => {
    console.log(type);
    setNewChildSeat({
      ...newChildSeat,
      [type]: newChildSeat[type] + 1,
    });
  };

  //handle add setNewChildSeat decrement
  const handleRemoveChildSeat = (type) => {
    if (newChildSeat[type] > 0) {
      setNewChildSeat({
        ...newChildSeat,
        [type]: newChildSeat[type] - 1,
      });
    }
  };

  const [newTotalBooking, setNewTotalBooking] = useState(0);

  useEffect(() => {
    dispatch(getSetting());
    fetchActiveVehicles(activeVehicleCallBackFunc);
  }, []);

  useEffect(() => {
    fetchActiveDrivers(activeDriversCallBackFunc);
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    if (!templates) return;
    const data = templates.map((row) => {
      return {
        value: row._id,
        label: row.templatename,
        subject: row.subject,
      };
    });
    setDDlTemplates(data);
  }, [templates]);

  const activeDriversCallBackFunc = (e_data) => {
    setActiveDrivers(e_data);
  };

  const fetchActiveDrivers = async (callback) => {
    const vt_data = await getAllDrivers();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.name,
      };
    });
    callback(data);
  };

  const activeVehicleCallBackFunc = (e_data) => {
    setActiveVehicles(e_data);
  };

  const fetchActiveVehicles = async (callback) => {
    const vt_data = await getAllActiveVehicles();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.vehiclename,
      };
    });
    callback(data);
  };

  useEffect(() => {
    showLoading(true);
    dispatch(getBooking(match.params.id));
  }, []);

  const checkHasTokyo = async (pickupLocation, destinations) => {
    var has_tokyo = false;
    if (
      pickupLocation.prefecture === "Tokyo" ||
      pickupLocation.prefecture === "東京" ||
      pickupLocation.prefecture === "東京中" ||
      pickupLocation.prefecture === "東京都" ||
      destinations[destinations.length - 1].prefecture === "Tokyo" ||
      destinations[destinations.length - 1].prefecture === "東京" ||
      destinations[destinations.length - 1].prefecture === "東京中" ||
      destinations[destinations.length - 1].prefecture === "東京都"
    ) {
      has_tokyo = true;
    }
    return has_tokyo;
  }

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setcheckChargeLoading(true);
        const bookingsData = await getBookingsByCartID(booking.cartID);
        console.log(bookingsData);
        setBookingsWithCartIdLength(bookingsData.length);

        const hasTokyo = await checkHasTokyo(booking.pickupLocation, booking.destinations);

        if (hasTokyo) {
          setChargeAccount("TLF")
        } else {
          setChargeAccount("JPT")
        }

      } catch (error) {
        console.error("Failed to fetch bookings:", error);
      } finally {
        setcheckChargeLoading(false);
      }
    };

    if (booking?.cartID) {
      fetchBookings();
    }
  }, [booking]);

  useEffect(() => {
    if (!loading && booking !== null) {
      // booking.bookingInfo.map((val, ind) => {
      //   var newArrayDriver;
      //   var newArray;
      //   if (typeof val?.driver !== "undefined" || val?.driver !== null) {
      //     const d_opt = activeDrivers.find(
      //       (opd) => opd.value === val?.driver?._id
      //     );
      //     newArrayDriver = driverSelectedOption;
      //     newArrayDriver[ind] = d_opt;
      //     setDriverSelectedOption(newArrayDriver);
      //   } else {
      //     newArrayDriver = driverSelectedOption;
      //     newArrayDriver[ind] = null;
      //     setDriverSelectedOption(newArrayDriver);
      //   }

      //   if (typeof val?.vehicle !== "undefined" || val?.vehicle !== null) {
      //     const opt = activeVehicles.find(
      //       (op) => op.label === val?.vehicle.vehiclename
      //     );
      //     newArray = selectedOption;
      //     newArray[ind] = opt;
      //     setSelectedOption(newArray);
      //   } else {
      //     newArray = selectedOption;
      //     newArray[ind] = null;
      //     setSelectedOption(newArray);
      //   }
      // });

      var bkStatus =
        loading || !booking?.bookingStatus ? "" : booking?.bookingStatus;
      const bk_opt = bookingStatusOptions.find((op) => op.label === bkStatus);
      setBookingStatus(bk_opt);
      switch (bkStatus) {
        case "CONFIRMED":
          setStyle("badge bg-success py-2");
          break;
        case "Payment Pending":
          setStyle("badge bg-primary py-2");
          break;
        case "Inquiry":
          setStyle("badge bg-warning py-2");
          break;
        case "Cancelled":
          setStyle("badge bg-danger py-2");
          break;
        default:
          setStyle("badge bg-dark py-2");
          break;
      }
      console.log(booking);

      const isVehicleControlerDisabled =
        booking?.bookingStatus === "Inquiry" ||
          booking?.bookingStatus === "Payment Pending" ||
          booking?.bookingStatus === "Cancelled"
          ? true
          : false;

      if (booking?.cartID !== null) dispatch(getCart(booking?.cartID));

      setIsDisabled(isVehicleControlerDisabled);
      closeLoading(true);
    }
  }, [booking]);

  const addNotes = () => {
    showLoading(true);
    if (note !== "") {
      const note_arr = [{ note: note, isAdmin: true }];

      dispatch(addNote(labelData._id, note_arr));
    }
    closeLoading(true);
    setNote("");
  };

  const updateBookingDriver = async (index = 0) => {
    showLoading(true);

    if (driverSelectedOption[index] !== null)
      dispatch(
        updateDriver(labelData._id, driverSelectedOption[index].value, index)
      );
    closeLoading(true);
  };

  const updateBooking = async () => {
    showLoading(true);
    // get available vehicles

    console.log(booking.bookingStatus)
    console.log(bookingStatus.label)
    if (booking.bookingStatus !== bookingStatus.label) {
      if (bookingStatus.label === "CONFIRMED") {
        const available_vehicles = await getAvailableVehicles(
          moment(booking.tripStartTime),
          moment(booking.garageArivalTimeWithAdditionalTime),
          booking.vehicleTypeName
        );
        if (available_vehicles) {
          const available_vehicle_id = available_vehicles[0]._id;
          dispatch(
            updateBookingStatus(
              booking._id,
              bookingStatus.label,
              available_vehicle_id
            )
          );
        } else {
          dispatch(
            setAlert("Vehicles are not available for the date.", "danger")
          );
        }
      } else if (
        bookingStatus.label === "CANCELLED" ||
        bookingStatus.label === "INQUIRY" ||
        bookingStatus.label === "PAYMENT_PENDING"
      ) {
        dispatch(updateBookingStatus(booking._id, bookingStatus.label, null));
      }
    }
    closeLoading(true);
  };

  const sendMailToClient = async () => {
    showLoading(true);

    const mailRes = await sendMail(
      labelData.email,
      "Your booking has been cancelled",
      getBookingCancelEmailTemplate(labelData)
    );

    if (mailRes.msg === "success") {
      dispatch(
        setAlert("Booking cancelation email sent to the customer.", "success")
      );
    } else {
      dispatch(setAlert("email is not sent", "error"));
    }
    closeLoading(true);
  };

  const sendCustomMailToClient = async () => {
    showLoading(true);
    const selectedTemplate = await getTemplate(ddltemplateOption.value);
    var htmltemp = selectedTemplate.htmlemail;
    if (htmltemp.includes("{{pickupLocation}}"))
      htmltemp = htmltemp.replace(
        "{{pickupLocation}}",
        booking.pickupLocation.label
      );
    if (htmltemp.includes("{{dropofflocation}}"))
      htmltemp = htmltemp.replace(
        "{{dropofflocation}}",
        booking.destinations
          .map((d) => {
            return d.label;
          })
          .join(" / ")
      );
    if (htmltemp.includes("{{drivername}}"))
      htmltemp = htmltemp.replace(
        "{{drivername}}",
        booking.driver ? booking.driver?.name : "N/A"
      );
    if (htmltemp.includes("{{vehiclename}}"))
      htmltemp = htmltemp.replace(
        "{{vehiclename}}",
        booking.vehicle.vehiclename
      );
    if (htmltemp.includes("{{vehicletype}}"))
      htmltemp = htmltemp.replace(
        "{{vehicletype}}",
        booking.vehicle.vehicletype
      );
    if (htmltemp.includes("{{pickuptime}}"))
      htmltemp = htmltemp.replace(
        "{{pickuptime}}",
        moment(booking.travelDate).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{dropofftime}}"))
      htmltemp = htmltemp.replace(
        "{{dropofftime}}",
        moment(
          booking.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime
        ).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{customername}}"))
      htmltemp = htmltemp.replace("{{customername}}", booking.customer.name);
    if (htmltemp.includes("{{cart}}"))
      htmltemp = htmltemp.replace("{{cart}}", getAdminCart());
    if (htmltemp.includes("{{packagename}}"))
      htmltemp = htmltemp.replace(
        "{{packagename}}",
        booking.activePackage ? booking.activePackage?.packagename : "N/A"
      );
    if (htmltemp.includes("{{totalcost}}"))
      htmltemp = htmltemp.replace(
        "{{totalcost}}",
        booking.bookingAmountInfo.total
      );

    if (htmltemp.includes("{{dropoffFlightno}}"))
      htmltemp = htmltemp.replace(
        "{{dropoffFlightno}}",
        booking.dropoffFlightNo
      );
    if (htmltemp.includes("{{pickupFlightno}}"))
      htmltemp = htmltemp.replace("{{pickupFlightno}}", booking.pickupFlightNo);
    if (htmltemp.includes("{{vehicleregistration}}"))
      htmltemp = htmltemp.replace(
        "{{vehicleregistration}}",
        booking.vehicle?.registration
      );
    setUpdatedHtmlstate(htmltemp);
    closeLoading(true);
  };

  useEffect(() => {
    if (updatedHtmlstate) sendUpdatedEmailTemplate();
  }, [updatedHtmlstate]);

  const sendUpdatedEmailTemplate = async () => {
    await sendMail(labelData.email, subject, updatedHtmlstate);
    dispatch(setAlert("Email has been sent.", "success"));
  };

  const getAdminCart = () => {
    var crt = "";

    return crt;
  };

  const chargeTokenHandler = async (
    id,
    byCartId = false,
    customFeeType = "",
    customFee = 0
  ) => {
    showLoading(true);
    if (byCartId) {
      const chargeTokenByCartIdRes = await chargeTokenByCartId(id);
      closeLoading(true);

      if (chargeTokenByCartIdRes.data.status === "success") {
        window.location.reload();
      } else {
        // console.log(chargeTokenByCartIdRes.data);
      }
    } else {
      if (customFeeType === "edited") {
        const chargeTokenRes = await chargeTokenEditedFee(id, customFee);
        closeLoading(true);

        if (chargeTokenRes.data.status === "success") {
          window.location.reload();
        } else {
          // console.log(chargeTokenRes.data);
        }
      } else if (customFeeType === "additional") {
        const chargeTokenRes = await chargeTokenAdditionalFee(id, customFee);
        closeLoading(true);

        if (chargeTokenRes.data.status === "success") {
          window.location.reload();
        } else {
          // console.log(chargeTokenRes.data);
        }
      } else {
        const chargeTokenRes = await chargeToken(id);
        closeLoading(true);

        if (chargeTokenRes.data.status === "success") {
          window.location.reload();
        } else {
          // console.log(chargeTokenRes.data);
        }
      }
    }
  };

  const chargeTokenWithRequestedChangesHandler = async (id) => {
    showLoading(true);
    const chargeTokenRes = await chargeTokenWithRequestedChanges(id);
    closeLoading(true);

    if (chargeTokenRes.data.status === "success") {
      window.location.reload();
    } else {
      // console.log(chargeTokenRes.data);
    }
  };

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalConfirmationCCInfo, setShowModalConfirmationCCInfo] = useState(false);
  const [modalConfirmationText, setModalConfirmationText] = useState("");
  const [continueAction, setContinueAction] = useState(() => () => { });

  // Open the confirmation modal and set the message
  const handleOpenModalConfirmation = (text, action) => {
    setModalConfirmationText(text);
    setContinueAction(() => action); // Set the continue action
    setShowModalConfirmation(true);
  };

  const handleOpenModalConfirmationUpdateCCInfo = (text, action) => {
    setShowModalConfirmationCCInfo(true);
  };

  // Close the confirmation modal
  const handleCloseModalConfirmation = () => {
    setShowModalConfirmation(false);
  };


  // Close the confirmation modal
  const handleCloseModalConfirmationUpdateCCInfo = () => {
    setShowModalConfirmationCCInfo(false);
  };

  // Function to handle the continuation action
  const handleContinue = () => {
    continueAction(); // Execute the stored continue action
    handleCloseModalConfirmation(); // Close the modal after action
  };

  const dateTimeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  const handleChangeUsageDuration = async () => {
    // var reqParamsAvailableVehicle = {
    //   pickupLocation: booking.pickuplocation,
    //   dropoffLocations: booking.dropofflocations,
    //   bookingType: booking.bookingType,
    //   travelDate: booking.traveldate,
    //   pickupTime: booking.pickuptime,
    //   pax: booking.pax,
    //   luggage: booking.lugage,
    //   flightTracking: (booking.flightTracking || booking.isAirportTransfer),
    // }
    // if (booking.bookingType === "byTheHour") {
    //   reqParamsAvailableVehicle = {
    //     ...reqParamsAvailableVehicle,
    //     usageDuration: sfd.duration,
    //     travelArea: sfd.travelArea
    //   }
    // }
    // const availableVehicle = await getAvailableVehicle(reqParamsAvailableVehicle);
    // if (availableVehicle.code !== 200 || availableVehicle.desc !== "SUCCESS" || availableVehicle.data === null) {
    // setmodalAlertText("Internal Server Error");
    // setShowModalAlert(true);
    // }
    // const {
    //   total_time,
    //   totalNightHours,
    //   startFromGarage,
    //   travelDateWithPickupTime,
    //   customerDropoffDateTime,
    //   garageArrivalTime,
    //   totalDistance,
    //   garage,
    //   additionalTime,
    //   breakTime,
    //   flightTrackingTime,
    //   usageDuration,
    //   billedDuration,
    //   appliedPackage,
    //   availableVehicleTypes,
    // } = availableVehicle.data;
    // console.log(availableVehicle.data)
  };

  // Close the confirmation modal
  const sumAdditionalFee = () => {
    var childSeatPrice = booking.bookingInfo
      .reduce(
        (sum, booking) => sum + booking.childSeatPrice,
        0
      );
    var meetAssistPrice = booking.meetAssist.reduce((sum, item) => sum + item.meetAssistPrice, 0);
    // console.log(childSeatPrice, meetAssistPrice);
    return (childSeatPrice + meetAssistPrice).toLocaleString();
  };

  const sendMailChangeCCInfoHandler = async (cartID) => {
    setShowModalConfirmationCCInfo(false);
    showLoading(true);
    const sendMailChangeCCInfoRes = await sendMailChangeCCInfo(cartID);
    closeLoading(true);

    if (sendMailChangeCCInfoRes.data.status === "success") {
      // console.log("success")
      // console.log(sendMailChangeCCInfoRes.data);
    } else {
      // console.log(sendMailChangeCCInfoRes.data);
    }

  }

  return (
    <Layout>
      <ModalConfirmation
        handleClose={handleCloseModalConfirmation}
        show={showModalConfirmation}
        text={modalConfirmationText}
        handleContinue={handleContinue}
      />

      <ModalConfirmation
        handleClose={handleCloseModalConfirmationUpdateCCInfo}
        show={showModalConfirmationCCInfo}
        text="Send user email to update card info?"
        handleContinue={() => sendMailChangeCCInfoHandler(booking.cartID)}
      />
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar
          title={!labelData.isAbandoned ? `Manage Booking` : `Abandoned cart`}
        />
        {!loading && booking != null && (
          <Fragment>
            <div className="container-fluid">
              <div className="row">
                <div className="row mb-3">
                  <div className="col">
                    <Link
                      className="btn btn-secondary"
                      to={`/admin/bookinglist`}
                    >
                      <i
                        className="fa fa-arrow-left fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Back To List
                    </Link>
                  </div>
                </div>
                {/* <Alert /> */}
                <div className="col">
                  <div className="card mb-3 shadow-sm">
                    <div className="card-header">CUSTOMER INFORMATION</div>
                    <div className="card-body pt-4 px-4">
                      {/* DESKTOP */}
                      <div className="">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col">
                                <p className="fs-14">Passenger Information</p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-md-3">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Prefix
                                </p>
                                <b className="fs-14">
                                  {booking.passengerPronounced}
                                </b>
                              </div>
                              <hr className="divider mt-2 d-block d-md-none" />

                              <div className="col-6 col-md-3">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Passenger's Name
                                </p>
                                <b className="fs-14">{booking.passengerName}</b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Passengers
                                </p>
                                <b className="fs-14">
                                  Adult: {booking.pax[0]?.paxcount}
                                </b>
                                <br />
                                <b className="fs-14">
                                  Child: {booking.pax[1]?.paxcount} / Toddles:{" "}
                                  {booking.pax[2]?.paxcount} / Infant:{" "}
                                  {booking?.pax[3]?.paxcount}
                                </b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Luggages
                                </p>
                                <b className="fs-14">
                                  Check-in Size :{" "}
                                  {booking?.luggage[0]?.luggagecount}
                                </b>
                                <br />
                                <b className="fs-14">
                                  CABIN Size :{" "}
                                  {booking?.luggage[1]?.luggagecount}
                                </b>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col">
                                <p className="fs-14">Billing Information</p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-md-2">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Reservation Holder
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.name}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Phone Number
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.phone}
                                </b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Email
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.email}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Country
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.country}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Postcode
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.postal_code}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      Booking Information{" "}
                      <span
                        style={{
                          border: "1px solid ",
                          borderRadius: "5px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Cart ID : {booking.cartID} | {booking.paymentStatus}
                      </span>
                    </div>
                    <div className="card-body text-dark">
                      <div className="row">
                        <div className="col mb-2">
                          <h6 className="card-title">Booking Type</h6>
                          <p
                            className={`card-text py-1 ${booking.bookingType === "byTheHour"
                              ? "badge bg-secondary py-2"
                              : booking.bookingType === "specifiedDestination" && booking.isAirportTransfer
                                ? "badge bg-light py-2 text-dark"
                                : "badge bg-dark py-2"
                              }`}
                          >
                            {booking.bookingType === "byTheHour"
                              ? "By The Hour"
                              : booking.bookingType === "specifiedDestination" && booking.isAirportTransfer
                                ? "Aiport Transfer"
                                : "Specified Destination"}
                          </p>
                          <h6 className="card-title">Current Status</h6>
                          <p className={`card-text py-1 ${style}`}>
                            {booking.bookingStatus}
                          </p>
                          <h6 className="card-title">Total Distance</h6>
                          <p className="card-text py-1 text-start">
                            {booking.totalDistance / 1000} KM
                          </p>
                          <h6 className="card-title">Pickup Time</h6>
                          <p className="card-text py-1 mb-2">
                            {moment(booking?.pickupDateTime).format(
                              "YYYY/MM/DD hh:mm A"
                            )}
                          </p>
                          <h6 className="card-title">Dropoff Time</h6>
                          <p className="card-text py-1 mb-2">
                            {moment(booking?.customerDropoffDateTime).format(
                              "YYYY/MM/DD hh:mm A"
                            )}
                          </p>
                          <h6 className="card-title">
                            Total Hours Usage{" "}
                            <span
                              className="ms-2 text-gold"
                              style={{ cursor: "pointer" }}
                              onClick={() => setEditHourUsage(!editHourUsage)}
                            >
                              {editHourUsage ? "Close" : "Edit"}
                            </span>
                          </h6>
                          {editHourUsage ? (
                            <div className="col-6 d-flex gap-2 mb-2 mt-2">
                              <DatePicker
                                locale={lang === "EN" ? "en" : "ja"}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption={"New Hours Usage"}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                calendarContainer={dateTimeContainer}
                                onChange={(date) => {
                                  var formatedTime = date
                                    .toTimeString()
                                    .slice(0, 5);
                                  setNewHourUsage(formatedTime);
                                  // var tge = moment(formatedDate);
                                  // setByTheHourData({
                                  //   ...byTheHourFormData,
                                  //   pickuptime: tge.format("HH:mm"),
                                  // });

                                  // handleChangeValidation("pickuptime", true);
                                  // availabilityValueReset();
                                }}
                                customInput={
                                  <div className="form-floating position-relative">
                                    <input
                                      readOnly={true}
                                      type="text"
                                      name="pickuptime"
                                      className={
                                        "form-control form-control-icon"
                                      }
                                      placeholder="Pick-up time"
                                      aria-label="Pick-up time"
                                      value={newHourUsage}
                                    />

                                    <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                                      <div className="d-flex justify-content-center">
                                        <img alt="img" src={ClockIcon} />
                                      </div>
                                    </span>
                                    <label
                                      for="floatingInput"
                                      className="label-icon"
                                    >
                                      Total Hours Usage
                                    </label>
                                  </div>
                                }
                              />
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  handleChangeUsageDuration();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <p className="card-text py-1">
                              {booking.usageDuration} Hours
                            </p>
                          )}
                          <h6 className="card-title">Billed Duration</h6>
                          <p className="card-text py-1">
                            {booking.billedDuration} Hours
                          </p>
                          <h6 className="card-title">Additional Time</h6>
                          <p className="card-text py-1">
                            {Math.floor(booking.additionalTime ?? 0)} Minutes
                          </p>
                          <h6 className="card-title">Break Time</h6>
                          <p className="card-text py-1">
                            {Math.floor(booking.breakTime ?? 0)} Minutes
                          </p>
                          <h6 className="card-title">Flight Tracking Time</h6>
                          <p className="card-text py-1">
                            {Math.floor(booking.flightTrackingTime ?? 0)}{" "}
                            Minutes
                          </p>
                        </div>
                        <div className="col">
                          <div className="row">
                            <h6 className="card-title">Update Status </h6>

                            <div className="d-flex justify-content-start">
                              <div className="col">
                                <Select
                                  classNamePrefix="select pick-up location"
                                  autoComplete="off"
                                  value={bookingStatus}
                                  onChange={(e) => {
                                    console.log(e)
                                    setBookingStatus(e);
                                  }}
                                  options={bookingStatusOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col ps-2 pt-3">
                              <button
                                // type="submit"
                                disabled={booking.isAbandoned}
                                className="btn btn-secondary ms-1"
                                onClick={updateBooking}
                              >
                                <i
                                  className="fa fa-wrench fa-fw"
                                  aria-hidden="true"
                                ></i>{" "}
                                Update
                              </button>

                              {booking.bookingStatus === "Cancelled" ? (
                                <button
                                  disabled={booking.isAbandoned}
                                  onClick={sendMailToClient}
                                  className="btn btn-secondary ms-1"
                                >
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Send
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title mt-4">
                                Calculated dispatch from Garage
                              </h6>
                              <p className="card-text py-1">
                                {moment(booking.startFromGarage).format(
                                  "YYYY/MM/DD hh:mm A"
                                )}
                              </p>
                              <h6 className="card-title">
                                Calculated arrival at Garage
                              </h6>
                              <p className="card-text py-1">
                                {moment(booking.garageArivalTime).format(
                                  "YYYY/MM/DD hh:mm A"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="card-title">Pickup Location</h6>
                      <p className="card-text py-1">
                        {booking.pickupLocation.label}
                      </p>

                      <h6 className="card-title">Destinations</h6>
                      {booking.destinations ? (
                        booking.destinations.map((d) => (
                          <p key={d._id} className="card-text py-1">
                            {d.label}
                          </p>
                        ))
                      ) : (
                        <h4>No destinations found...</h4>
                      )}

                      {booking.stripeRef && (
                        <>
                          <h6 className="card-title">Stripe Ref</h6>
                          <p>
                            {booking.stripeRef || "No Stripe Reference found"}
                          </p>
                        </>
                      )}

                      {booking.flywireResponse && (
                        <>
                          <h6 className="card-title">Flywire Token</h6>
                          <p>{booking.flywireToken || "No Token found"}</p>
                        </>
                      )}

                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Notes</h6>
                          <p className="card-text py-1">
                            {booking.message ? (
                              booking.message.map((d) => (
                                <p key={d._id} className="card-text py-1">
                                  {moment(d.date).format("YYYY/MM/DD hh:mm A")}
                                  {" (" +
                                    (d.isAdmin ? "admin" : "customer") +
                                    ")"}
                                  {" :  "}
                                  {d.note}
                                </p>
                              ))
                            ) : (
                              <h4>No Luggage found...</h4>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="label mb-2">Note :</label>
                            <div className="col">
                              <textarea
                                className={`form-control form-control-admin`}
                                rows="2"
                                placeholder="Text here..."
                                name="note"
                                value={note}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-secondary ms-1"
                            onClick={addNotes}
                          >
                            <i
                              className="fa fa-wrench fa-fw"
                              aria-hidden="true"
                            ></i>{" "}
                            Add Note
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      {" "}
                      <Alert />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  {booking.requestChangeBookingData && (
                    booking.requestChangeBookingData.requestType === "reschedule" ? (
                      <div className="card mb-3">
                        <div className="card-header">
                          Request Change Booking Information
                        </div>
                        <div className="card-body text-dark">
                          <div className="row">
                            <div className="col mb-2">
                              <h6 className="card-title">
                                Current Request Status
                              </h6>
                              <p
                                className={`card-text py-1 ${booking.requestChangeBookingData
                                  .approvalStatus === 0
                                  ? "badge bg-warning py-2"
                                  : booking.requestChangeBookingData
                                    .approvalStatus === 1
                                    ? "badge bg-success py-2"
                                    : "badge bg-danger py-2"
                                  }`}
                              >
                                {booking.requestChangeBookingData
                                  .approvalStatus === 0
                                  ? "Waiting for Approval"
                                  : booking.requestChangeBookingData
                                    .approvalStatus === 1
                                    ? "Approved"
                                    : "Rejected"}
                              </p>
                              <h6 className="card-title">Pickup Time</h6>
                              <p className="card-text py-1 mb-2">
                                {moment(
                                  booking.requestChangeBookingData
                                    .travelDateRequest
                                ).format("YYYY/MM/DD hh:mm A")}
                              </p>
                              <h6 className="card-title">Dropoff Time</h6>
                              <p className="card-text py-1 mb-2">
                                {moment(
                                  booking.requestChangeBookingData
                                    .dropoffTimeRequest
                                ).format("YYYY/MM/DD hh:mm A")}
                              </p>
                              <button
                                onClick={() => history.push(`/admin/cancel-reschedule-request/manage/${booking.requestChangeBookingData._id}`)}
                                className="btn btn-secondary ms-1"
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                ></i>{" "}
                                Go to Change Booking Request Detail
                              </button>
                            </div>
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <h6 className="card-title mt-4">
                                    Calculated dispatch from Garage
                                  </h6>
                                  <p className="card-text py-1">
                                    {moment(
                                      booking.requestChangeBookingData
                                        .startFromGarage
                                    ).format("YYYY/MM/DD hh:mm A")}
                                  </p>
                                  <h6 className="card-title">
                                    Calculated arrival at Garage
                                  </h6>
                                  <p className="card-text py-1">
                                    {moment(
                                      booking.requestChangeBookingData
                                        .arrivalAtGarage
                                    ).format("YYYY/MM/DD hh:mm A")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {booking.requestChangeBookingData
                            .pickupLocationRequest && (
                              <>
                                <h6 className="card-title">Pickup Location</h6>
                                <p className="card-text py-1">
                                  {
                                    booking.requestChangeBookingData
                                      .pickupLocationRequest.formatted_address
                                  }
                                </p>
                              </>
                            )}

                          <h6 className="card-title">Destinations</h6>
                          {booking.requestChangeBookingData
                            .destinationsRequest ? (
                            booking.requestChangeBookingData.destinationsRequest.map(
                              (d) => (
                                <p key={d._id} className="card-text py-1">
                                  {d.label}
                                </p>
                              )
                            )
                          ) : (
                            <h4>No destinations found...</h4>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="card mb-3">
                        <div className="card-header">
                          Cancellation Booking Request
                        </div>
                        <div className="card-body text-dark">
                          <div className="row">
                            <div className="col mb-2">
                              <h6 className="card-title">
                                Current Request Status
                              </h6>
                              <p
                                className={`card-text py-1 ${booking.requestChangeBookingData
                                  .approvalStatus === 0
                                  ? "badge bg-warning py-2"
                                  : booking.requestChangeBookingData
                                    .approvalStatus === 1
                                    ? "badge bg-success py-2"
                                    : "badge bg-danger py-2"
                                  }`}
                              >
                                {booking.requestChangeBookingData
                                  .approvalStatus === 0
                                  ? "Waiting for Approval"
                                  : booking.requestChangeBookingData
                                    .approvalStatus === 1
                                    ? "Approved"
                                    : "Rejected"}
                              </p>
                              <h6 className="card-title mt-4">
                                Percentage Cancellation Fee (%)
                              </h6>
                              <p className="card-text py-1">
                                {booking.requestChangeBookingData.percentCancelFee}%
                              </p>
                              <h6 className="card-title">
                                Refund Percentage (%)
                              </h6>
                              <p className="card-text py-1">
                                {booking.requestChangeBookingData.percentRefund}%
                              </p>
                              <button
                                onClick={() => history.push(`/admin/cancel-reschedule-request/manage/${booking.requestChangeBookingData._id}`)}
                                className="btn btn-secondary ms-1"
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                ></i>{" "}
                                Go to Cancellation Request Detail
                              </button>
                            </div>
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <h6 className="card-title mt-4">
                                    Cancellation Request Created
                                  </h6>
                                  <p className="card-text py-1">
                                    {moment(booking.requestChangeBookingData.requestCreatedAt).format("YYYY/MM/DD hh:mm A")}
                                  </p>
                                  <h6 className="card-title mt-4">
                                    Cancellation Fee
                                  </h6>
                                  <p className="card-text py-1">
                                    {booking.requestChangeBookingData.partialCancellationFee.toLocaleString()}JPY
                                  </p>
                                  <h6 className="card-title mt-4">
                                    Total Refund Amount
                                  </h6>
                                  <p className="card-text py-1">
                                    {booking.requestChangeBookingData.totalRefundAmount.toLocaleString()}JPY
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  <div className="card mb-3">
                    <div className="card-header">
                      Price and Route Information
                    </div>
                    <div className="card-body">
                      {booking.bookingInfo.map((item, index) => {
                        return (
                          <div key={index} className="row">
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Vehicle Type{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {item.vehicleTypeName}
                              </p>
                            </div>
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Base Price{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {item.baseprice.toLocaleString()} JPY
                              </p>
                            </div>
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                30 Minutes Rate{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {item.hourlyprice.toLocaleString()} JPY
                              </p>
                            </div>
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Total Hours{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {booking.billedDuration} (Billed Duration)
                              </p>
                            </div>
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Night Zone Hours{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {item.zoneHours ?? 0}
                              </p>
                            </div>
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Toll Fare{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {item.tollFare.toLocaleString()} JPY
                              </p>
                            </div>
                            {booking.requestChangeBookingData && booking.requestChangeBookingData.requestType !== "cancel" && booking.requestChangeBookingData.approvalStatus === 0 && (
                              <div className="col-md-3 mb-3">
                                <h6 className="card-title">
                                  Toll Fare {index + 1} After Changes
                                </h6>
                                <p className="card-text py-1">
                                  {booking.requestChangeBookingData.tollFare.toLocaleString()}{" "}
                                  JPY
                                </p>
                              </div>
                            )}
                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                User Added Estimate{" "}
                                {booking.bookingInfo.length > 1
                                  ? index + 1
                                  : ""}
                              </h6>
                              <p className="card-text py-1">
                                {(item.estimate ?? 0).toLocaleString()} JPY
                              </p>
                            </div>

                            {booking.bookingType === "byTheHour" && (
                              <>
                                <div className="col-md-3 mb-3">
                                  <h6 className="card-title">
                                    Selected Travel Area{" "}
                                    {booking.bookingInfo.length > 1
                                      ? index + 1
                                      : ""}
                                  </h6>
                                  {item.travelArea === "withinCity" && (
                                    <p className="card-text py-1 py-1 mb-1 text-success">
                                      Within the city
                                    </p>
                                  )}
                                  {item.travelArea ===
                                    "neighboringPerfecture" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        To the neighboring city
                                      </p>
                                    )}
                                  {item.travelArea === "undecided" && (
                                    <p className="card-text py-1 py-1 mb-1 text-success">
                                      Undecided
                                    </p>
                                  )}
                                </div>

                                <div className="col-md-3 mb-3">
                                  <h6 className="card-title">
                                    Travel Area Price{" "}
                                    {booking.bookingInfo.length > 1
                                      ? index + 1
                                      : ""}
                                  </h6>
                                  <p className="card-text py-1">
                                    {item.travelAreaPrice.toLocaleString() ||
                                      "-"}{" "}
                                    JPY
                                  </p>
                                </div>
                              </>
                            )}

                            <div className="col-md-3 mb-3">
                              <h6 className="card-title">
                                Additional Services
                              </h6>
                              <p className="m-0">
                                {item.childSeatPrice > 0 ? (
                                  <>
                                    {item.childSeat.map((seat, index) => (
                                      <span key={index}>
                                        {seat.childSeatCount > 0 && (
                                          <>
                                            {seat.childSeatType} Seat (x
                                            {seat.childSeatCount})<br />
                                          </>
                                        )}
                                      </span>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <hr className="hr" />
                      <div className="row mt-4">
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Activated Package</h6>
                          {booking.package !== null ? (
                            <div className="card-text py-1 py-1 mb-1">
                              <span>{booking.activePackage?.packagename} </span>
                            </div>
                          ) : (
                            <div className="card-text py-1 p-1 mb-1">
                              No package available
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Dropoff Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {booking.dropoffFlightNo}
                          </p>
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Pickup Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {booking.pickupFlightNo}
                          </p>
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Package Flat Fee</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark">
                            {booking.package?.vehicles
                              ?.find((v) => v.vtype === booking.vehicleTypeName)
                              ?.vehicleCost.toLocaleString() ?? "-"}{" "}
                            JPY
                          </p>
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Meet Service</h6>
                          {booking?.meetAssist &&
                            booking.meetAssist[0].meetAssistName ===
                            "meetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                Meet & Assist (Arrival)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[0].meetAssistName ===
                            "vipMeetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                VIP Meet & Assist (Arrival)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[1].meetAssistName ===
                            "meetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                Meet & Assist (Departure)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[1].meetAssistName ===
                            "vipMeetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                VIP Meet & Assist (Departure)
                              </p>
                            )}
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Pickup Sign</h6>
                          {booking?.pickupSign !== null
                            ? booking?.pickupSign
                            : "-"}
                        </div>

                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Additional Booking Fee</h6>
                          <p className="card-text py-1">
                            {sumAdditionalFee() + " JPY"}
                          </p>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setAddChildSeat(!addChildSeat)}
                          >
                            Add Child Seat
                          </button>
                          {addChildSeat && (
                            <div className="card mt-2">
                              <div className="card-body">
                                {/* Infant Seat */}
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="m-0">Infant</p>
                                  <div className="d-flex justify-content-center gap-3">
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleRemoveChildSeat("infant")
                                      }
                                    >
                                      <i className="bi bi-dash"></i>
                                    </button>
                                    <div className="d-flex align-items-center">
                                      {newChildSeat.infant}
                                    </div>
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleAddChildSeat("infant")
                                      }
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </div>
                                </div>
                                {/* Toddler Seat */}
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                  <p className="m-0">Toddler</p>
                                  <div className="d-flex justify-content-center gap-3">
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleRemoveChildSeat("toddler")
                                      }
                                    >
                                      <i className="bi bi-dash"></i>
                                    </button>
                                    <div className="d-flex align-items-center">
                                      {newChildSeat.toddler}
                                    </div>
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleAddChildSeat("toddler")
                                      }
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </div>
                                </div>
                                {/* Child Seat */}
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                  <p className="m-0">Child</p>
                                  <div className="d-flex justify-content-center gap-3">
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleRemoveChildSeat("child")
                                      }
                                    >
                                      <i className="bi bi-dash"></i>
                                    </button>
                                    <div className="d-flex align-items-center">
                                      {newChildSeat.child}
                                    </div>
                                    <button
                                      type="button"
                                      className={`btn btn-circle btn-outline-dark-2 align-items-center justify-content-center`}
                                      onClick={() =>
                                        handleAddChildSeat("child")
                                      }
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                  <p>Total</p>
                                  <p>
                                    {
                                      //count newChildSeat and multiply by 1100
                                      (
                                        (newChildSeat.infant +
                                          newChildSeat.toddler +
                                          newChildSeat.child) *
                                        1100
                                      ).toLocaleString()
                                    }
                                    JPY
                                  </p>
                                </div>
                                <div className="d-grid">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 mb-3">
                          <h6 className="card-title">Total booking cost</h6>
                          <p className="card-text py-1 py-1 mb-1 text-success">
                            {booking.totalPrice.toLocaleString()} JPY
                          </p>
                        </div>
                        {booking.totalPrevPrice && (
                          <div className="col-md-3 mb-3">
                            <h6 className="card-title">
                              Total previous booking cost
                            </h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {booking.totalPrevPrice.toLocaleString()} JPY
                            </p>
                          </div>
                        )}
                        {booking.requestChangeBookingData && booking.requestChangeBookingData.requestType !== "cancel" && booking.requestChangeBookingData.approvalStatus === 0 && (
                          <div className="col-md-3 mb-3">
                            <h6 className="card-title">
                              Total booking cost After Changes
                            </h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {booking.requestChangeBookingData.totalPrice.toLocaleString()}{" "}
                              JPY
                            </p>
                          </div>
                        )}

                      </div>
                      <hr className="hr" />
                      <div className="row">
                        <div className="col-md-8">
                          <h6 className="card-title">
                            Charge Status -{" "}
                            <p
                              className={`card-text py-1 ${booking.paymentStatus === "CHARGE_PENDING" &&
                                !booking.flywireChargeReference
                                ? "badge bg-warning py-2"
                                : booking.paymentStatus === "CHARGED" &&
                                  booking.flywireChargeReference
                                  ? "badge bg-success py-2"
                                  : ""
                                }`}
                            >
                              {booking.paymentStatus === "CHARGE_PENDING" &&
                                !booking.flywireChargeReference
                                ? "CHARGE_PENDING"
                                : booking.paymentStatus === "CHARGED" &&
                                  booking.flywireChargeReference
                                  ? "Success"
                                  : ""}
                            </p>
                          </h6>
                          <div className="d-flex align-items-center">
                            <div className="col-6 mb-3 mt-3">
                              <p className="m-0">Flywire Charge Account : {chargeAccount}</p>
                            </div>
                          </div>
                          {booking.paymentMethodStatus === "CHANGED" && (
                            <div className="d-flex align-items-center">
                              <div className="col-6 mb-3 mt-3">
                                <p className="m-0">CC Info : CHANGED at {moment(booking.paymentMethodUpdatedAt).format(
                                  "YYYY/MM/DD hh:mm A"
                                )}</p>
                              </div>
                            </div>
                          )}
                          <div className="d-flex align-items-center">
                            <div className="col-3">
                              <p className="m-0">Charge New Total Amount</p>
                            </div>
                            <div className="col-3">
                              <input
                                type="number"
                                class="form-control"
                                aria-describedby="helpId"
                                placeholder="Amount"
                                onChange={(e) =>
                                  setNewTotalBooking(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <button
                                disabled={
                                  booking.isAbandoned ||
                                  !booking.flywireToken ||
                                  booking.bookingStatus !== "CONFIRMED"
                                }
                                className="btn btn-secondary ms-2"
                                onClick={() => {
                                  handleOpenModalConfirmation(
                                    `Are you sure you want to charge this booking ${booking.paymentStatus === "CHARGE_PENDING"
                                      ? "with edited total booking cost"
                                      : (booking.paymentStatus === "CHARGED" || booking.paymentStatus === "CHARGE_INITIATED")
                                        ? "with added additional fee"
                                        : ""
                                    }?`,
                                    () =>
                                      chargeTokenHandler(
                                        booking._id,
                                        false,
                                        booking.paymentStatus ===
                                          "CHARGE_PENDING"
                                          ? "edited"
                                          : booking.paymentStatus === "CHARGED"
                                            ? "additional"
                                            : "",
                                        newTotalBooking
                                      )
                                  );
                                }}
                              >
                                <i
                                  className="fa fa-money fa-fw"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                                {booking.paymentStatus === "CHARGE_PENDING" && "Charge With Edited Total Booking Cost"}
                                {(booking.paymentStatus === "CHARGED" || booking.paymentStatus === "CHARGE_INITIATED") && "Add Additional Fee "}
                              </button>
                            </div>
                          </div>
                          <hr className="divider" />
                          {booking?.cartID && (
                            <div className="d-flex mt-3 gap-3">
                              {checkChargeLoading ? (
                                <>
                                  <span
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  &nbsp; Checking charge options...
                                </>
                              ) : (
                                <>
                                  {bookingsWithCartIdLength > 1 &&
                                    booking.paymentStatus !== "CHARGED" && (
                                      <>
                                        <button
                                          disabled={
                                            booking.isAbandoned ||
                                            !booking.flywireToken ||
                                            booking.bookingStatus !== "CONFIRMED" ||
                                            booking.paymentStatus !== "CHARGE_PENDING"
                                          }
                                          className="btn btn-secondary ms-1"
                                          onClick={() => {
                                            handleOpenModalConfirmation(
                                              "Are you sure want to charge the whole cart?",
                                              () =>
                                                chargeTokenHandler(
                                                  booking.cartID,
                                                  true,
                                                  "",
                                                  0
                                                )
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-money fa-fw"
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;
                                          {booking.paymentStatus ===
                                            "CHARGE_PENDING" &&
                                            !booking.flywireChargeReference
                                            ? "Charge with the whole cart!"
                                            : ""}
                                        </button>
                                      </>
                                    )}
                                  <button
                                    disabled={
                                      booking.isAbandoned ||
                                      !booking.flywireToken ||
                                      booking.bookingStatus !== "CONFIRMED" ||
                                      booking.paymentStatus !== "CHARGE_PENDING"
                                    }
                                    className="btn btn-secondary ms-1"
                                    onClick={() => {
                                      handleOpenModalConfirmation(
                                        "Are you sure want to charge this booking?",
                                        () =>
                                          chargeTokenHandler(
                                            booking._id,
                                            false,
                                            "",
                                            0
                                          )
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-money fa-fw"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;
                                    {
                                      booking.paymentStatus === "CHARGE_PENDING" && !booking.flywireChargeReference ? `Charge!`
                                        : booking.paymentStatus === "CHARGE_INITIATED" ? "Initiated by Flywire"
                                          : booking.paymentStatus === "CHARGED" && booking.flywireChargeReference ? "Successfuly Charged" : ""
                                    }
                                  </button>

                                  <button
                                    disabled={
                                      booking.isAbandoned ||
                                      booking.paymentStatus === "CHARGED"
                                    }
                                    // onClick={() => sendMailChangeCCInfoHandler(booking.cartID)}
                                    onClick={handleOpenModalConfirmationUpdateCCInfo}
                                    className="btn btn-secondary ms-1"
                                  >
                                    <i
                                      className="fa fa-envelope-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Send Email to Change CC Info
                                  </button>
                                </>
                              )}

                              {booking.paymentStatus === "CHARGE_PENDING" &&
                                booking.requestChangeBookingData &&
                                booking.requestChangeBookingData
                                  .currentStatus === "CHARGE_PENDING" && (
                                  <button
                                    disabled={
                                      booking.isAbandoned ||
                                      !booking.flywireToken ||
                                      booking.bookingStatus !== "CONFIRMED" ||
                                      booking.paymentStatus !== "CHARGE_PENDING"
                                    }
                                    className="btn btn-secondary ms-1"
                                    onClick={() => {
                                      chargeTokenWithRequestedChangesHandler(
                                        booking._id
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-checklist"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp; Charge Booking and Approve Requested
                                    Changes!
                                  </button>
                                )}
                            </div>
                          )}
                        </div>
                        {booking.flywireChargeResponse && (
                          <div className="col-md-4">
                            <h6 className="card-title">Reference</h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {booking.flywireChargeResponse.reference}
                            </p>

                            {booking.flywireChargeResponse && (
                              <div className="row">
                                <div className="col">
                                  <h6 className="card-title">Payer Amount</h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {`${booking.flywireChargeResponse.payerAmount
                                      } ${booking.flywireChargeWithCart
                                        ? "- With the whole cart"
                                        : ""
                                      }`}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">
                                    Payer Amount Currency
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {
                                      booking.flywireChargeResponse
                                        .payerAmountCurrency
                                    }
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">Amount</h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {`${booking.flywireChargeResponse.amount} ${booking.flywireChargeWithCart
                                      ? "- With the whole cart"
                                      : ""
                                      }`}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">
                                    Amount Currency
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {
                                      booking.flywireChargeResponse
                                        .amountCurrency
                                    }
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {booking.flywireChargeAdditionalFeeResponse && (
                          <div className="col-md-4">
                            <h6 className="card-title">
                              Reference Additional Fee
                            </h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {
                                booking.flywireChargeAdditionalFeeResponse
                                  .reference
                              }
                            </p>

                            {booking.flywireChargeAdditionalFeeResponse && (
                              <div className="row">
                                <div className="col">
                                  <h6 className="card-title">
                                    Payer Amount Additional Fee
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {`${booking.flywireChargeAdditionalFeeResponse
                                      .payerAmount
                                      } ${booking.flywireChargeWithCart
                                        ? "- With the whole cart"
                                        : ""
                                      }`}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">
                                    Payer Amount Currency Additional Fee
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {
                                      booking.flywireChargeAdditionalFeeResponse
                                        .payerAmountCurrency
                                    }
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">
                                    Amount Additional Fee
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {`${booking.flywireChargeAdditionalFeeResponse
                                      .amount
                                      } ${booking.flywireChargeWithCart
                                        ? "- With the whole cart"
                                        : ""
                                      }`}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">
                                    Amount Currency Additional Fee
                                  </h6>
                                  <p className="card-text py-1 py-1 mb-1 text-success">
                                    {
                                      booking.flywireChargeAdditionalFeeResponse
                                        .amountCurrency
                                    }
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    {/* <div className="card-header">Email Template</div> */}
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-4">
                          <h6 className="card-title">Template Name</h6>
                        </div>
                        <div className="col">
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="select pick-up location"
                            value={ddltemplateOption}
                            onChange={(e) => {
                              setSubject(e.subject);
                              setddlTemplateOption(e);
                            }}
                            options={ddltemplate}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <h6 className="card-title">Email Subject</h6>
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col ps-2">
                          <div className="mt-3 ps-0">
                            <button
                              disabled={booking.isAbandoned}
                              onClick={sendCustomMailToClient}
                              className="btn btn-secondary ms-1"
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Send Email
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className={`card mb-3`}>
                    <div className="card-header">Assigned vehicle</div>
                    {booking.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body" key={index}>
                          <div className="row mb-3">
                            <div className="col-4">
                              <h6 className="card-title">
                                Vehicle Name {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {isDisabled ? (
                                  <span className="badge bg-info">
                                    Vehicle not assigned.
                                  </span>
                                ) : (
                                  item?.vehicle?.vehiclename
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Vehicle Type {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item.vehicleTypeID.typename}
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Registration {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item.vehicle.registration}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Change Vehicle {index + 1}
                              </h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    isDisabled={isDisabled}
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={selectedOption[index]}
                                    onChange={(e) => {
                                      selectedOption[index] = e;
                                      // setSelectedOption(newArr);
                                    }}
                                    options={activeVehicles}
                                  />
                                </div>
                                <button
                                  disabled={isDisabled}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={(e) => {
                                    setSelectedOption((selectedOption) => ({
                                      ...selectedOption,
                                      [index]: e,
                                    }));
                                  }}
                                >
                                  <i
                                    className="fa fa-wrench fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}

                  {/* <div className="card mb-3">
                    <div className="card-header">Driver Information</div>
                    {booking.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body" key={index}>
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Driver Name {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.name !== undefined ? (
                                  item?.driver?.name
                                ) : (
                                  <span className="badge bg-info">
                                    Driver not assigned.
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Driver Email {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.email}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Driver Phone {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.phone}
                              </p>
                            </div>
                            <div className="col-7">
                              <h6 className="card-title">
                                Update Driver {index + 1}
                              </h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={driverSelectedOption[index]}
                                    onChange={(e) => {
                                      setDriverSelectedOption(
                                        (driverSelectedOption) => ({
                                          ...driverSelectedOption,
                                          [index]: e,
                                        })
                                      );
                                    }}
                                    options={activeDrivers}
                                  />
                                </div>
                                <button
                                  disabled={booking.isAbandoned}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={() => updateBookingDriver(index)}
                                >
                                  <i
                                    className="fa fa-plus-circle fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </main>
    </Layout>
  );
};

export default ManageBookingV2;
