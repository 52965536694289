/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getRouteCarForTotalTime } from "../../../actions/navitimeAPI";
import moment from "moment";
import AdminInputLocation from "../components/AdminInputLocation";
import AdminInputDatePicker from "../components/AdminInputDatePicker";
import Select from "react-select";
import { travelAreaOptionsEnglish } from "../../../utils/dataLists";
import AdminInputTimePicker from "../components/AdminInputTimePicker";
import AdminInputPassengers from "../components/AdminInputPassengers";
import AdminInputLuggage from "../components/AdminInputLuggage";
import LuggageIcon from "../../../icons/form-icons/luggage.svg";
import date_icon from "../../../icons/dashboard/date_icon.svg";
import LocationPinIcon from "../../../icons/form-icons/location-pin.svg";
import plus_icon from "../../../icons/plus.svg";
import PaxIcon from "../../../icons/form-icons/pax.svg";

export const SpecifiedDestinationQuotation = ({ searchAvailableVehicles, editFormAvailableVehicle = null }) => {

  const [formData, setByFormData] = useState({
    pickupLocation: null,
    dropoffLocations: [null],
    usageDuration: null,
    travelDate: null,
    pickupTimeRaw: null,
    estpPickupTime: null,
    travelArea: null,
    luggage: {
      cabinSize: 0,
      checkinSize: 0,
    },
    pax: {
      adult: 0,
      child: 0,
      toddler: 0,
      baby: 0,
    },
    byTheHourBook: false,
    bookingType: "specifiedDestination",
    isAirportTransfer: false,
    isFromAirport: false,
    isToAirport: false,
    isStationTransfer: false,
    isFromStation: false,
    isToStation: false,
  });

  useEffect(() => {
    if (editFormAvailableVehicle != null) {
      console.log("ADA EDIT NYA")
      console.log(editFormAvailableVehicle);
      setByFormData(editFormAvailableVehicle);
    }
  }, [])

  const handlePickupLocation = (location) => {
    if (location === undefined) return;
    const city = location.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    location.lat = location.geometry.location.lat;
    location.lon = location.geometry.location.lng;
    location.label = location.name + ", " + location.formatted_address;
    location.formatted_address = location.name + ", " + location.formatted_address;
    location.value = location.place_id;
    location.city = city;
    location.prefecture = city;
    location.description = location.description
    setByFormData({ ...formData, pickupLocation: location });
  };

  const handleDropOffLocation = (location, index) => {
    const city = location.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    location.lat = location.geometry.location.lat;
    location.lon = location.geometry.location.lng;
    location.label = location.name + ", " + location.formatted_address;
    location.formatted_address = location.name + ", " + location.formatted_address;
    location.value = location.place_id;
    location.description = location.description
    location.city = city;
    location.prefecture = city;
    const newDropoffLocations = [...formData.dropoffLocations];
    newDropoffLocations[index] = location;

    console.log(index);
    console.log(newDropoffLocations);
    setByFormData({ ...formData, dropoffLocations: newDropoffLocations });
  };

  const handleTravelDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setByFormData({ ...formData, travelDate: formattedDate });
  };

  const handlePickupTime = (time) => {
    const formattedTime = moment(time).format("HH:mm");
    setByFormData({ ...formData, pickupTimeRaw: formattedTime });
  };

  const handleOnChangeLuggage = (luggage, amount) => {
    setByFormData((prevForm) => {
      const newLugage = { ...prevForm.luggage };

      if (luggage === "cabin") {
        newLugage.cabinSize = Math.max(0, newLugage.cabinSize + amount);
      } else if (luggage === "checkin") {
        newLugage.checkinSize = Math.max(0, newLugage.checkinSize + amount);
      }

      return {
        ...prevForm,
        luggage: newLugage,
      };
    });
  };

  const handleOnChangePax = (pax, amount) => {
    setByFormData((prevForm) => {
      const newPax = { ...prevForm.pax };

      if (pax === "adult") {
        newPax.adult = Math.max(0, newPax.adult + amount);
      } else if (pax === "child") {
        newPax.child = Math.max(0, newPax.child + amount);
      } else if (pax === "toddler") {
        newPax.toddler = Math.max(0, newPax.toddler + amount);
      } else if (pax === "baby") {
        newPax.baby = Math.max(0, newPax.baby + amount);
      }

      return {
        ...prevForm,
        pax: newPax,
      };
    });
  };

  const checkIsAirportOrStation = (pickupLocation, dropoffLocations) => {
    var pickUpStatus = false;
    var dropoffStatus = false;
    var pickupType = "none";
    var dropoffType = "none";

    if (pickupLocation) {
      // if (pickupLocation.types.includes("airport")) {
      //   pickUpStatus = true;
      //   pickupType = "airport"
      // }

      const trimmedPlace = pickupLocation.formatted_address.toLowerCase().split(",");

      for (let index = 0; index < trimmedPlace.length; index++) {
        if ((trimmedPlace[index].includes("haneda") && trimmedPlace[index].includes("airport")) || (trimmedPlace[index].includes("羽田") && trimmedPlace[index].includes("空港"))) {
          pickUpStatus = true;
          pickupType = "airport"
        }

        if ((trimmedPlace[index].includes("narita") && trimmedPlace[index].includes("airport")) || (trimmedPlace[index].includes("成田") && trimmedPlace[index].includes("空港"))) {
          pickUpStatus = true;
          pickupType = "airport"
        }

        if ((trimmedPlace[index].includes("tokyo") && trimmedPlace[index].includes("station")) || (trimmedPlace[index].includes("東京") && trimmedPlace[index].includes("駅"))) {
          pickUpStatus = true;
          pickupType = "station"
        }

      }
    }

    if (dropoffLocations[dropoffLocations.length - 1].address_components) {
      // if (dropoffLocations[dropoffLocations.length - 1].types.includes("airport")) {
      //   dropoffStatus = true;
      //   dropoffType = "airport"
      // }

      const trimmedPlaceDropoff = dropoffLocations[dropoffLocations.length - 1].formatted_address.toLowerCase().split(",");

      for (let ind = 0; ind < trimmedPlaceDropoff.length; ind++) {
        if ((trimmedPlaceDropoff[ind].includes("haneda") && trimmedPlaceDropoff[ind].includes("airport")) || (trimmedPlaceDropoff[ind].includes("羽田") && trimmedPlaceDropoff[ind].includes("空港"))) {
          dropoffStatus = true;
          dropoffType = "airport"
        }

        if ((trimmedPlaceDropoff[ind].includes("narita") && trimmedPlaceDropoff[ind].includes("airport")) || (trimmedPlaceDropoff[ind].includes("成田") && trimmedPlaceDropoff[ind].includes("空港"))) {
          dropoffStatus = true;
          dropoffType = "airport"
        }

        if ((trimmedPlaceDropoff[ind].includes("tokyo") && trimmedPlaceDropoff[ind].includes("station")) || (trimmedPlaceDropoff[ind].includes("東京") && trimmedPlaceDropoff[ind].includes("駅"))) {
          dropoffStatus = true;
          dropoffType = "station"
        }

      }
    }

    console.log("CHECK!")
    console.log(pickUpStatus || dropoffStatus)

    return {
      check: pickUpStatus || dropoffStatus,
      pickupStatus: pickUpStatus,
      dropoffStatus: dropoffStatus,
      dropoffType,
      pickupType
    }

  }

  useEffect(() => {
    if (
      formData.pickupLocation != null &&
      formData.dropoffLocations != null &&
      formData.dropoffLocations.length > 0 &&
      !formData.dropoffLocations.includes(null)
    ) {

      const { check, pickupStatus, dropoffStatus, dropoffType, pickupType } = checkIsAirportOrStation(formData.pickupLocation, formData.dropoffLocations);

      console.log({ check, pickupStatus, dropoffStatus, dropoffType, pickupType });
      console.log("{check, pickupStatus, dropoffStatus, dropoffType, pickupType}");

      if (check) {
        if (dropoffType === "airport" || pickupType === "airport"){
          // onChangeAirportTransfer(e, pickupStatus, dropoffStatus)
          setByFormData({ ...formData, isFromAirport: pickupStatus, isToAirport: dropoffStatus, isAirportTransfer: true });
        } else if(dropoffType === "station" || pickupType === "station"){
          // onChangeStationTransfer(e, pickupStatus, dropoffStatus) 
          setByFormData({ ...formData, isFromStation: pickupStatus, isToStation: dropoffStatus, isStationTransfer: true });
        }
      }

      }
    }, [formData.pickupLocation, formData.dropoffLocations]);

  const handleAddDestination = () => {
    setByFormData((prevForm) => {
      const newDropoffLocation = null;

      return {
        ...prevForm,
        dropoffLocations: [...prevForm.dropoffLocations, newDropoffLocation],
      };
    });
  };

  const handleRemoveDestination = (index) => {
    setByFormData((prevForm) => {
      const newDropoffLocations = [...prevForm.dropoffLocations];
      newDropoffLocations.splice(index, 1);

      return {
        ...prevForm,
        dropoffLocations: newDropoffLocations,
      };
    });
  }




  useEffect(() => {
    if (formData.pickupLocation != null && formData.dropoffLocations != null && formData.dropoffLocations.length > 0 && formData.travelDate != null && formData.pickupTimeRaw != null && Object.values(formData.pax).some(paxCount => paxCount > 0)) {
      searchAvailableVehicles(formData);
    }
  }, [formData])

  return (
    <div className="">
      <div className="row mb-2">
        <div className="col-12">
          <AdminInputLocation
            prefixIcon={LocationPinIcon}
            placeholder="Pick-up: Airport, Hotel, Attraction, etc"
            onSelectLocation={handlePickupLocation}
            mb="mb-3"
            label={"Pick-up"}
            value={formData?.pickupLocation?.formatted_address}
          />
        </div>
        <div className="col-12">
          <label htmlFor="" className="mb-2">Drop Off</label>
          {formData.dropoffLocations.map((item, index) => (<div key={index}>
            <div className="d-flex gap-2 align-items-start mb-3" >
              <div className="col">
                <AdminInputLocation
                  prefixIcon={LocationPinIcon}
                  placeholder="Drop-off: Airport, Hotel, Attraction, etc."
                  onSelectLocation={handleDropOffLocation}
                  mb="mb-0"
                  hasIndex={true}
                  index={index}
                  value={formData?.dropoffLocations[index]?.formatted_address}
                />
              </div>
              {index === formData.dropoffLocations.length - 1 ? <button className="btn bg-gold-light-100 rounded py-2" onClick={handleAddDestination}>
                <div className="d-flex gap-2 px-3">
                  <img src={plus_icon} alt="" srcSet="" />
                  <p className="m-0 noto">
                    Add destination
                  </p>
                </div>
              </button> : <p className="m-0 pointer" onClick={() => handleRemoveDestination(index)}> X </p>}
            </div>
          </div>))}

        </div>

      </div>
      <div className="row mb-2">
        <div className="col-3">
          <AdminInputDatePicker
            label={"Travel Date"}
            placeholder="Select travel date"
            prefixIcon={date_icon}
            onSelectDate={handleTravelDate}
            mb={""}
            name="travelDate"
            value={formData.travelDate != null ? moment(formData.travelDate, "YYYY-MM-DD").toDate() : null}
          />
        </div>
        <div className="col-3">
          <AdminInputTimePicker
            label={"Pick-up Time"}
            placeholder="Select Pick-up Time"
            prefixIcon={date_icon}
            onSelectDate={handlePickupTime}
            name="travelDate"
            mb="mb-0"
            value={formData.pickupTimeRaw != null ? moment.utc(formData.pickupTimeRaw, "HH:mm").toDate() : null}
          />
          {/* <p className="fs-13 mt-1 mb-0">
            <span className="fw-bold">
              {formData.estpPickupTime ?? ""}
            </span>
          </p> */}
        </div>
        <div className="col-3">
          <AdminInputPassengers
            label={"Passenger"}
            placeholder="Input Passenger"
            prefixIcon={PaxIcon}
            totalAdult={formData.pax.adult}
            totalBaby={formData.pax.baby}
            totalToddler={formData.pax.toddler}
            totalChild={formData.pax.child}
            onChange={handleOnChangePax}
            value={
              formData.pax.adult +
              formData.pax.child +
              formData.pax.toddler +
              formData.pax.baby
            }
          />
        </div>
        <div className="col-3">
          <AdminInputLuggage
            label={"Luggage"}
            placeholder="Input Luggage"
            prefixIcon={LuggageIcon}
            totalCabin={formData.luggage.cabinSize}
            totalCheckin={formData.luggage.checkinSize}
            onChange={handleOnChangeLuggage}
            value={
              formData.luggage.cabinSize + formData.luggage.checkinSize
            }
          />
        </div>
      </div>
      <div className="row mb-2">

      </div>
    </div>
  );
};
